import React from "react"
import Layout from "~components/layouts/default"
import Container from "~components/common/container"
import SEO from "~components/common/seo"
import AniLink from "~components/common/anilink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-5/8">
          <h1>Seite nicht gefunden.</h1>
          <p>Wir konnten den gewünschten Inhalt hier leider nicht finden.</p>
          <AniLink to="/" className="accessory">Zur Startseite</AniLink>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
